import React from "react";
import Navbar from "react-bootstrap/Navbar";
import "./Layout/Layout.css";
import { withRouter } from "react-router-dom";

class Hellobar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }


  render() {

    const style = {
      textAlign: 'center',
      display: 'block',
      backgroundColor: '#ebf9ff',
      fontWeight: 700,
      padding: 0
    };

    const textStyle = {
      color: 'black',
      width: '90%'
    }

    const linkStyle = {
      textDecoration: 'none'
    }
    this.goToAccess = () => {
      let path = 'access';
      this.props.history.push(path);
    }

    this.generateSpan = () => {
      if (this.props.helloLink.includes('http')) {
        return (<span class="navbar-text" style={textStyle}>
          <span role="img" aria-label="tada"> 🎉</span>
          <a href={this.props.helloLink} style={linkStyle}>
            {this.props.helloNews}
          </a>
        </span>);
      } else {
        return (<Navbar.Text onClick={this.goToAccess} style={textStyle}>
          <span role='img' aria-label="tada"> 🎉</span>
          <a href={this.props.helloLink} style={linkStyle}>{this.props.helloNews}</a>
        </Navbar.Text>);
      }
    }

    return (
      <Navbar
        className="hello-bar justify-content-between header"
        fixed="top"
        sticky="top"
        expand="md"
        style={style}
      >
        {this.generateSpan()}
      </Navbar>
    );
  }
}

export default withRouter(Hellobar);
