import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import MediaQuery from "react-responsive";
import { withRouter, Link } from "react-router-dom";
import ComparisonItem from "./ComparisonItem";
import FeaturedVideo from "../../sharedComponents/FeaturedVideo";
import { DataContext } from "../../../contexts/DataContext";
import GetStarted from "../../sharedComponents/GetStarted";

import GSuite from "../../../img/comparison/GSuite.png";
import Microsoft from "../../../img/comparison/microsoft.png";
import CommonApp from "../../../img/comparison/CommonApp.png";
import Parchment from "../../../img/comparison/parchment.png";
import PowerSchool from "../../../img/comparison/PowerSchool.png";
import HumanEsources from "../../../img/comparison/HumanEsources.png";
import VeraCross from "../../../img/comparison/veracross.png";
import YouScience from "../../../img/comparison/YouScience.png";
import Isams from "../../../img/comparison/isams.png";
import Stroke from "../../../img/Stroke11.svg";
import Line from "../../../img/Line.svg";

import "./Comparison.css";

class Comparison extends React.Component {
  static contextType = DataContext;

  generateRow = (startIndex, cols) => {
    const { data } = this.props;
    let table = [];
    for (var i = startIndex; i < startIndex + cols; i++) {
      table.push(
        <Col key={i} className="featureContentBlock">
          <h4 className="featureContentTitle">
            {data["comparisonFeatureItemTitle" + i]}
          </h4>
          <p className="featureContentDescription">
            {data["comparisonFeatureItemDescription" + i]}
          </p>
          {data["comparisonFeatureItemLinkLabel" + i] && (
            <div className="featureContentLinkWrapper">
              <Link
                className={
                  "featureContentLink " +
                  [
                    "homePageFeaturesSendDocumentLinkText",
                    "homePageFeaturesLeverageLinkText",
                    "homePageFeaturesDiscoverLinkText"
                  ][i % 3]
                }
                to={data["comparisonFeatureItemLink" + i] || "home"}
              >
                {data["comparisonFeatureItemLinkLabel" + i]}
              </Link>
            </div>
          )}
        </Col>
      );
    }
    return (
      <Row key={startIndex} className="center-in-row">
        {table}
      </Row>
    );
  };

  generateTable = cols => {
    let table = [];

    for (var i = 1; i <= 3; i += cols) {
      table.push(this.generateRow(i, cols));
    }

    return table;
  };

  render() {
    const { data } = this.props;
    return (
      <Container className="comparisonPageContainer" fluid>
        <Row>
          <div className="comparisonHeader">
            <h1 className="headerTitle">{data.comparisonHeaderTitle}</h1>
            <p className="headerDescription">
              {data.comparisonHeaderDescription}
            </p>
            <div className="getStartedWrapperWrapper">
              <GetStarted
                sendEmailAddressToGetADemo={
                  this.props.sendEmailAddressToGetADemo
                }
                placeholder={data.comparisonHeaderGetStartedPlaceholder}
                label={data.comparisonHeaderGetStartedButtonLabel}
              />
            </div>
          </div>
        </Row>

        <Row className="featuresContainer">
          <div className="featuresContainerTopArc"></div>
          <h1 className="featuresTitle">{data.comparisonFeaturesTitle}</h1>
          <p className="headerDescription">
            {data.comparisonFeaturesDescription}
          </p>
          {/* <div> */}
          <MediaQuery query="(min-width: 1224px)">
            {this.generateTable(3)}
          </MediaQuery>
          <MediaQuery query="(max-width: 1223px) and (min-width: 768px)">
            {this.generateTable(2)}
          </MediaQuery>
          <MediaQuery query="(max-width: 767px)">
            {this.generateTable(1)}
          </MediaQuery>
          {/* </div> */}
        </Row>

        <h1 className="solutionsTitle">{data.comparisonSolutionsTitle}</h1>
        <ComparisonItem
          title={data.comparisonSolutionTitle1}
          description={data.comparisonSolutionDescription1}
          order={"one"}
          image={data.comparisonSolutionImage1}
          linkLabel={data.comparisonSolutionLinkLabel1}
          link={data.comparisonSolutionLink1}
        />
        <Row className="testimonialContainer">
          <h1>{data.comparisonTestimonialText}</h1>
          <Row className="testimonialUser">
            <img
              alt="avatar"
              src={data.comparisonTestimonialAvatar}
              className="testimonialAvatar"
            />
            <div className="testimonialInfo">
              <span className="testimonialName">
                {data.comparisonTestimonialName}
              </span>
              <p className="testimonialJobTitle">
                {data.comparisonTestimonialJobTitle}
              </p>
            </div>
          </Row>
        </Row>
        <ComparisonItem
          left
          title={data.comparisonSolutionTitle2}
          description={data.comparisonSolutionDescription2}
          order={"two"}
          image={data.comparisonSolutionImage2}
          linkLabel={data.comparisonSolutionLinkLabel2}
          link={data.comparisonSolutionLink2}
        />
        <Row className="integrationsContainer">
          <div className="integrationsPanel">
            <h1 className="integrationsTitle">
              {data.comparisonIntegrationsTitle}
            </h1>
            <p className="integrationsDescription">
              {data.comparisonIntegrationsDescription}
            </p>
            <div className="integrationsList">
              <img className="integrationItem" src={GSuite} alt="GSuite" />
              <img
                className="integrationItem"
                src={Microsoft}
                alt="Microsoft"
              />
              <img
                className="integrationItem"
                src={CommonApp}
                alt="CommonApp"
              />
              <img
                className="integrationItem"
                src={Parchment}
                alt="parchment"
              />
            </div>
            <div className="integrationsList">
              <img
                className="integrationItem"
                src={PowerSchool}
                alt="PowerSchool"
              />
              <img
                className="integrationItem"
                src={HumanEsources}
                alt="HumanEsources"
              />
              <img
                className="integrationItem"
                src={VeraCross}
                alt="veracross"
              />
              <img
                className="integrationItem"
                src={YouScience}
                alt="YouScience"
              />
              <img className="integrationItem" src={Isams} alt="isams" />
            </div>
          </div>
        </Row>
        <ComparisonItem
          title={data.comparisonSolutionTitle3}
          description={data.comparisonSolutionDescription3}
          order={"three"}
          image={data.comparisonSolutionImage3}
          linkLabel={data.comparisonSolutionLinkLabel3}
          link={data.comparisonSolutionLink3}
        />
        <FeaturedVideo />
        <Row className="getStartedContainer">
          <h1 className="getStartedTitle">{data.comparisonGetStartedTitle}</h1>
          <div className="actionsContainer">
            <div className="actionContainer">
              <h2 className="actionName">
                {data.comparisonGetStartedActionName1}
              </h2>
              <div className="actionBtnWrapper">
                <Link
                  className={"actionBtn"}
                  to={data.comparisonGetStartedActionLink1}
                >
                  {data.comparisonGetStartedActionButton1}
                </Link>
              </div>
            </div>
            <div className="actionSeparator">OR</div>
            <div className="actionContainer">
              <h2 className="actionName">
                {data.comparisonGetStartedActionName2}
              </h2>
              <div className="actionBtnWrapper">
                <Link className={"actionBtn"} to={"/demo"}>
                  {data.comparisonGetStartedActionButton2}
                </Link>
              </div>
            </div>
          </div>
          <img className="stroke" src={Stroke} alt="" />
          <img className="line" src={Line} alt="" />
        </Row>
      </Container>
    );
  }
}

export default withRouter(Comparison);
