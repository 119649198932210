import Row from "react-bootstrap/Row";
import React from "react";
import "../getADemo/GetADemo.css";
import "../privacyAndSecurity/privacyAndSecurity.css";
import * as contentful from "contentful";
import Container from "react-bootstrap/Container";
import { withRouter } from "react-router-dom";

class TermsOfServiceUS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <Container className="secondary_font">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <p>These Terms and Conditions are a part of the Software as a Service Agreement Form (“Agreement”) between Cialfo Inc. (“Cialfo”), a Delaware entity, and the Customer named in the SaaS Agreement Form of which these terms form a part and are incorporated by this reference.</p>
          <p>For good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows intending to be legally bound:</p>

          <h5 class="text-uppercase"><strong>1. DEFINITIONS</strong></h5>

          <p>In addition to other terms define herein, unless the context otherwise requires, the following terms have the following meanings:</p>
          <p><strong>“Intellectual Property Rights”</strong> means all vested, contingent and/or future intellectual property rights including but not limited to copyright, trademarks, service marks, design rights (whether registered or unregistered), patents, know-how, trade secrets, inventions, database rights and any application for the protection or registration or these rights and all registrations, renewals and extensions thereof existing in any part of the world whether now known or in the future created.</p>
          <p><strong>“Fees”</strong> means any and all the fees and other amounts payable by Customer to Cialfo pursuant to this Agreement, including without limitation the fees payable for the Subscription Service, and any other fees and expenses payable by Customer as agreed to by the parties at any time after the date hereof</p>
          <p><strong>“Personal Data”</strong> means, with respect to Customer’s Users, personally identifiable information of or about, or that relates to, describes, is capable of being associated with, or could reasonably be linked directly or indirectly with, an individual or a household, including without limitation any data that falls within the scope of personal data or personal information within applicable privacy, data protection and data security laws and regulations.</p>
          <p><strong>“Subscription Service”</strong> means, subject to payment of the Fees, the provision by Cialfo to Customer of online access to the System during the Subscription Term.</p>
          <p><strong>“Subscription Term”</strong> means the subscription period during which Customer has purchased access to the Subscription Service, as specified in the Agreement, and any renewals thereof, subject to earlier termination for Fees non-payment and as otherwise provided in this Agreement.</p>
          <p><strong>“System”</strong> means the Cialfo standard web-accessed college counseling internet platform and software service, including any standard updates thereto that may be provided hereunder to Customer during the Subscription Term, and all standard content, benchmarking data, college information and documentation in such online service made available to users from time to time or generated by users from accessing and using the tools available through the System.</p>
          <p><strong>“US$”</strong> means the lawful currency of the United States of America;</p>
          <p><strong>“User(s)”</strong> means employees of the Customer, students then-actively enrolled in the Customer’s school in the grades indicated in the Agreement, and parents and guardians of the enrolled students to whom the Customer has granted access to the System through the Subscription Service, regardless of whether the User actually accesses or uses the System.</p>
          <p><strong>“User Information”</strong> means any and all data, materials and information pertaining to, or related to a User or the User’s household that is made accessible, disclosed, provided, transferred or uploaded to Cialfo or the System, or that is submitted to or generated or processed by the System in connection with the Customer’s or any User’s access or use of the Subscription Service.</p>
          <p>This includes without limitation, the Personal Data, registration information, password and user names, college selection lists, assessments, transcripts, and recommendations</p>

          <h5 class="text-uppercase"><strong>2. SUBSCRIPTION SERVICE AND USE RIGHT</strong></h5>

          <p>2.1 During the Subscription Term, Customer orders and Cialfo will provide the Subscription Service to the Customer as set forth in and subject to the terms and conditions of this Agreement.  Cialfo grants to the Customer a non-exclusive, non-sublicensable and non-transferable right to permit the Customer’s Users to access the System from locations within the United States for the Customer’s internal business purposes in connection with the Customer’s college counseling functions (the <strong>“Use Right”</strong>). This Agreement is for services and no software or other tangible materials will be delivered by Cialfo to Customer.</p>
          <p>2.2 The Parties agree that:</p>
          <p>(a) the Customer shall be entitled to display its logo and other Customer branding on the System in such areas where the System allows such Customer branding; and</p>
          <p>(b) Cialfo shall be identified in the System as the technology provider of the Customer.</p>
          <p>2.3 The Customer shall designate up to (2) system administrators (<strong>“System Administrators”</strong>). The System Administrators shall be suitably experienced and responsible for managing User access to the Subscription Service including but not limited to adding and subtracting Users, and shall be the designated individuals for communicating with Cialfo with respect to requests for available System support. The System Administrators shall ensure that multiple Users do not share a password or user name. Customer acknowledges and agrees that it is prohibited from sharing or providing passwords and/or user names with or to unauthorized users or anyone outside of Customer’s employees and enrolled student base.</p>
          <p>2.4 Customer will provide Cialfo with reasonable cooperation in relation to this Agreement, and necessary access to such personnel, materials and information as may be required by Cialfo in order to implement and make available the Subscription Service.</p>
          <p>2.5 The Customer agrees that Customer is solely responsible for obtaining and maintaining in effect all necessary consents, authorizations and disclosures from Users to collect, store, generate, process, transfer, disclose, share, host and otherwise allow the System and Cialfo to use and host all User Information.</p>
          <p>2.6 Customer represents and warrants to Cialfo that it will at all times comply with all applicable laws relating to data privacy, protection and security with respect to its collection of User Information and access and use of the Subscription Service and for the  provision of User Information to and collection by Cialfo, and that Customer has all necessary rights, consents and authorizations for Cialfo to provide the Subscription Service and collect, host, use, process and transfer the User Information (including without limitation with respect to the Cialfo mobile app and for communications with Users via SMS and email).</p>
          <p>2.7 Customer agrees and undertakes with Cialfo that its privacy policy with the Users shall contain provisions which entitle Cialfo and its affiliates to collect, process, transfer and use User Information to provide the Subscription Service, and to use, modify, de-identify and/or aggregate User Information for Cialfo’s and its affiliates’ internal analytics and benchmarking and to improve the services (and Customer hereby grants such rights to Cialfo and its affiliates).</p>
          <p>2.8 Both the Customer and Cialfo will take reasonable steps to avoid the risk of any inadvertent disclosure or unauthorized access to the System. The Customer acknowledges and agrees that the System is not intended for use with protected sensitive information such as credit card and other payment card numbers, financial account numbers, and other financial data. The Customer will use commercially reasonable efforts and means to avoid input to or use of such sensitive information with the System. Without limiting the foregoing, Customer must ensure that sensitive information of students and others is not uploaded to the System except where minimally necessary to use the System and is only viewable by such Users’ who have a need to know the specific information.  Sensitive information should not be included in Notes and other areas of the System that can be viewed by individuals who do not have a right or need to do so. Customer assumes all risk arising</p>
          <p>from use of any such sensitive information with the System including but not limited to the risk of any inadvertent or unauthorized disclosure or unauthorized access thereto or violation of laws pertaining to such type of information. The Customer is responsible for ensuring that the Customer and Customer’s users’ use of the System is in compliance with all applicable laws and governmental regulations. The Customer acknowledges and agrees to assume all risk arising from any such use that is not compliant with applicable laws and regulations.</p>
          <p>The Customer agrees to the privacy policy of Cialfo (“Privacy Policy”) located at this link: https://cialfo.co/privacy-policy. The Customer shall ensure that its privacy policy is not inconsistent with the Cialfo Privacy Policy as it relates to the data collection, processing, use and transfer necessary for the provision of the Subscription Service. The Customer further acknowledges and agrees that Cialfo as well as its representatives and/or agents may from time to time amend, modify or otherwise update this Privacy Policy at its discretion, without prior notification to the Customer. The Customer shall be bound by the Privacy Policy as may be amended, modified or otherwise updated. Customer is urged to visit the link above periodically to be apprised of changes to the Privacy Policy.</p>
          <p>2.9 Cialfo has the right to monitor and audit Customer’s and Users’ access and use of the System to determine compliance with this Agreement. Customer agrees to provide information reasonably requested by Cialfo to validate compliance with this Agreement.</p>
          <p>2.10  The Customer hereby authorizes Cialfo to use Customer’s name and logo for Cialfo’s marketing activities to designate Customer as a user of the System including but not limited to publishing the name and logo on Cialfo’s web and mobile applications.</p>
          <p>2.11  The System provides access to useful links, data exchange and integrations with and from third-party sites, service providers and content.  Customer acknowledges that Cialfo does not control those sites or third-parties or the timeliness, accuracy or completeness of the content they provide.  Cialfo is not responsible for errors and omissions in third-party sites, services and operations. Customer agrees that all functionality and links available on the System are only for use in connection with using the System in accordance with this Agreement.</p>

          <h5 class="text-uppercase"><strong>3. TERM</strong></h5>

          <p>3.1 Subject to Clause 3.2 below and until or unless terminated in accordance with any of the provision of this Agreement, this Agreement shall be in effect during the Subscription Term stated in the Agreement Form, and shall commence on the date stated in page 1 of the Agreement Form and shall terminate on the end of the date stated in page 1 of the Agreement Form (“Initial Period”).</p>
          <p>3.2 At the end of the Initial Period, this Agreement shall be automatically renewed for:</p>
          <p>(a) a further period of the same duration as the Initial Period; or</p>
          <p>(b) one (1) year</p>
          <p>whichever is shorter, unless either Party gives the other Party not less than ninety (90) days advance written notice prior to the renewal date of its intention not to renew this Agreement. Cialfo will provide Customer information about renewal rates prior to renewal time.</p>

          <h5 class="text-uppercase"><strong>4. PAYMENT</strong></h5>

          <p>4.1 In consideration for the Subscription Service, the Use Right and services and other undertakings provided by Cialfo to the Customer, the Customer agrees to pay the Fees. Fees are non-refundable.</p>
          <p>4.2 The Parties agree that in the event of termination of this Agreement (for whatsoever reason and howsoever arising) prior to the agreed expiration date of this Agreement, the Customer shall be liable to make payment in respect of all amounts payable by the Customer to Cialfo for the entire duration of this Agreement (the full Subscription Term) as if this Agreement has been performed in full.</p>
          <p>4.3 The Fees and other charges payable under this Agreement are exclusive of any applicable sales, use, goods, service or other taxes and governmental assessments anywhere, all of which shall be payable by the Customer at the rate and in the manner prescribed by law.</p>
          <p>4.4 All payments by the Customer under this Agreement shall be paid in US Dollars without set-off or counterclaim and free and clear of and without set-off, deduction or withholding for or on account of any taxes, levies, imposts, duties, fees, assessments or other charges of whatever nature, imposed by any relevant jurisdiction, or by any department, agency or other political subdivision or taxing authority thereof, and all interest, penalties or similar liabilities with respect thereto (<strong>“Taxes”</strong>), unless deduction or withholding of such Taxes is required by applicable law, in which event the Customer shall pay such additional amount as shall be required to ensure that the net amount received by Cialfo will equal the full amount which would have been received by them had no such deduction or withholding been made.</p>

          <h5 class="text-uppercase"><strong>5. RESTRICTIONS</strong></h5>

          <p>The Customer agrees that Customer will not (and will not permit any other person or entity to):</p>
          <p>(a) subcontract, assign, rent, lease, transfer or use as a service bureau for others the Use Right or the System or use the System to process data of third-parties (other than the Customer’s own enrolled students in the applicable grades)</p>
          <p>(b) translate, adapt, vary, modify, disassemble, decompile or reverse engineer the System or any of its code or components except to the extent any of these restrictions is prohibited by applicable law;</p>
          <p>(c) create any derivative works of or based upon the System, access or reference the System in order to create similar products;</p>
          <p>(d) make copies of the System, in whole or in part.</p>

          <h5 class="text-uppercase"><strong>6. SECURITY; AVAILABILITY STANDARD</strong></h5>

          <p>6.1 The Customer shall during the Subscription Term:</p>
          <p>(a) effect and maintain adequate security measures to safeguard the System (including System credential for access and System access points) from access or use by any unauthorized persons;</p>
          <p>(b) retain access to the System and all System access points under the Customer’s effective and sole control;</p>
          <p>(c)  require that all Users exercise reasonable efforts to safeguard access and use of the System and only upload to the System such User Information as is minimally necessary to use the System for the contemplated purposes; and</p>
          <p>(d) maintain a full and accurate record of the Customer’s and Users’ use, and disclosure of the System and shall produce such record to Cialfo on request from time to time.</p>
          <p>6.2 The Customer shall immediately notify Cialfo if it becomes aware of any actual or suspected loss or theft or unauthorized use of any of the Customer’s passwords and/or usernames, and shall promptly disable or require changing of such passwords and usernames.</p>
          <p>6.3 Cialfo will make commercially reasonable efforts to provide System Availability Level (as determined in the manner set out below) of the System of not less than 98% in a calendar month. System Availability Level shall be determined according to the following formula:</p>
          <p>System availability = [Operating hours - System Downtime] / [Operating hours] x 100%.</p>
          <p>For the purpose of Sections 6.3 and 6.4:</p>
          <p>“Operating Hours" means the scheduled operating hours of the System in a calendar month which will be, unless otherwise specified, for twenty-four (24) hours on a daily basis including all Saturdays, Sundays and public holidays, excluding scheduled maintenance windows.</p>
          <p>"System Downtime" means the accumulated time during which the System is not available for use due to the issue of software configurations within the control of Cialfo measured by Cialfo from the time of such failure is reported by Customer to Cialfo to the time when the System is returned to operation. For the avoidance of doubt:</p>
          <p>(a) For computation of System Downtime, the failure must be of Severity level 1 (as defined below);</p>
          <p>(b) Cialfo shall not be responsible for unavailability of the System due to issues relating to hosting of the System on third party cloud server or due to force majeure events;</p>
          <p>(c) Scheduled maintenance shall not be included in the computation of System Downtime. Cialfo shall provide the Customer with two (2) days prior notice of any scheduled maintenance.</p>
          <p>6.4 Cialfo agrees that:</p>
          <p>(a) in the case where the System is down and not available for access by Customer (Severity Level 1), Cialfo shall rectify documented errors within reasonable time. Cialfo shall provide report on progress of such remedial action to the Customer within one (1) business day from the date Cialfo is notified of such incident.</p>
          <p>(b) in the case where the System can perform but some parts of it are affected (Severity Level 2), Cialfo shall rectify documented errors within reasonable time. Cialfo shall provide report on the progress of such remedial action to the Customer within two (2) business days from the date Cialfo is notified of such incident.</p>
          <p>(c) As noted in the remainder of this Section, Cialfo will have in place commercially reasonable physical, administrative and technical security safeguards for the Subscription Service designed to safeguard the Personal Data that is in the possession of Cialfo pursuant to this Agreement against unauthorized intrusions by third parties. The Customer agrees and acknowledges that Cialfo maintains all data that it collects in accordance with its internet security protocols which can be accessed at https://cialfo.co/security. For as long as Cialfo maintains the data that it has collected in accordance with its security protocol, it shall not be liable for any data breach or security incident.</p>
          <p>(d) Cialfo acknowledges that to the extent it receives any Personal Data it does so only in connection with the performance of the services it provides under this Agreement. Cialfo will only process the Personal Data as part of the services in accordance with this Agreement, and the Customer hereby gives Cialfo permission to access, transfer, store, use and process Personal Data as necessary to provide the Subscription Service, maintain, support and update the System and respond to queries from Customer and/or users regarding the system and the information. Customer is solely responsible for unauthorized access or use of the System or User Information resulting from Customer and/or Users’ passwords or user ids, or through any computer, mobile device or other access point to the System of or used by Customer or a user (including without limitation any access or use of the System through public Internet access points or shared computers).</p>

          <p>(e) In the event a User or anyone whose Personal Data is in the System requests pursuant to applicable law that Cialfo identify all data kept in the System about such person or require deletion of any such data, Cialfo will notify Customer and Customer shall notify the requester of the implications of any such request and pay for Cialfo’s reasonable expenses to fulfill such request to the extent required by applicable law. Cialfo bears no responsibility for any user’s inability to use the System due to a data deletion request.</p>

          <h5 class="text-uppercase"><strong>7. PROPRIETARY RIGHTS</strong></h5>

          <p>7.1 The Customer agrees that the System and the Intellectual Property Rights in and to the System and all components thereof are and shall remain the sole and exclusive property of Cialfo.</p>
          <p>7.2 The Customer shall notify Cialfo immediately if the Customer becomes aware of any unauthorized access or use of the whole or any part of the System and/or Intellectual Property Rights in the System by any person.</p>
          <p>7.3 The Customer acknowledges that any and all trademarks, trade names, copyrights, patents and other Intellectual Property Rights created, developed, embodied in or in connection with the System or any enhancement thereto shall be and remain the sole property of Cialfo and the Customer undertakes to assign (or cause to be assigned) all its interest therein, if any, to Cialfo or its nominee. The Customer shall not during or at any time after the termination of this Agreement in any way question or dispute the ownership by Cialfo of any such rights.</p>
          <p>7.4 Whenever requested to do so by Cialfo, the Customer shall execute any and all applications, assignments or other instruments which Cialfo deems reasonably necessary to give effect to this Section 7.</p>

          <h5 class="text-uppercase"><strong>8. WARRANTY, DISCLAIMER AND LIABILITY</strong></h5>

          <p>8.1. Each party represents and warrants to the other that it has the necessary authority to enter into this Agreement and this Agreement does not conflict with any contracts, orders or obligations to which it is bound.</p>
          <p class="text-uppercase">8.2 EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, NEITHER PARTY MAKES ANY WARRANTIES WITH RESPECT TO THE SERVICES OR PRODUCTS OR ANY MATTER UNDER THIS AGREEMENT, AND BOTH PARTIES HEREBY DISCLAIM ALL OTHER WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, SECURITY,  NON-INFRINGEMENT,  BASED ON COURSE OF DEALING OR USAGE OF TRADE, OR ANY WARRANTIES REGARDING ANY RESULTS TO BE ACHIEVED FROM THE USE OF THE SUBSCRIPTION SERVICE OR THAT THE SERVICE OR INTERNET TRANSMISSIONS WILL OPERATE WITHOUT ANY INTERRUPTION OR BE TOTALLY FREE FROM ERRORS. THE PARTIES DISCLAIM THE APPLICABILITY OF ANY LAWS ADOPTING THE UNIFORM COMPUTER INFORMATION TRANSACTIONS ACT (UCITA) AND THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS.</p>

          <p class="text-uppercase">8.3 REGARDLESS OF THE FORM OF ACTION OR LEGAL THEORY, IN NO EVENT SHALL EITHER PARTY, OR THEIR RESPECTIVE AFFILIATES BE LIABLE TO THE OTHER PARTY OR THE OTHER PARTY’S AFFILIATES OR ANY THIRD PARTY WITH RESPECT TO ANY MATTER ARISING UNDER OR RELATING TO THE SUBSCRIPTION SERVICE OR THIS AGREEMENT FOR (I) ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR FOR DAMAGES RESULTING FROM LOST PROFITS, LOSS OF USE, LOSS OF BUSINESS OPPORTUNITIES OR DEPLETION OF GOODWILL, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (II)  IN THE AGGREGATE FOR DIRECT, COMPENSATORY OR ANY AND ALL DAMAGES IN EXCESS OF  AN AMOUNT EQUAL TO THE FEES PAID BY CUSTOMER TO CIALFO DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM(S).</p>

          <p>8.4 Cialfo agrees to indemnify, defend and hold harmless Customer and Customer's affiliates from and against any damages awarded or settlements required to be paid cost and expense which Customer or its affiliate suffer or incur as a result of a third-party action, claim or demand against any of them alleging that the System (excluding the User Information and any other content uploaded to the System by Customer, users or any third-parties, and any third-party sites or content linked to from or through the System) infringes any third-party Intellectual Property Rights when the System is accessed and used by Users in accordance with this Agreement.</p>

          <p>8.5 Customer agrees to indemnify, defend and hold harmless Cialfo and Cialfo's affiliates from and against any damages awarded or settlements required to be paid, costs and expenses which Cialfo or its affiliate suffer or incur as a result of a third-party action, claim or demand relating to or arising out of the User Information, acts or omissions of Users or Customer personnel or contractors, or any allegation that User Information was made available or provided to Cialfo or its affiliate in violation of applicable law or without the necessary consents and authorizations from Users or other third-parties.</p>

          <h5 class="text-uppercase"><strong>9. CONFIDENTIALITY</strong></h5>

          <p>9.1 Each Party (“receiving party”) undertakes, except as provided below, to treat as confidential all information disclosed to it by or on behalf of the other Party or an affiliate of the other Party (the “disclosing party”) that is marked “confidential” or with similar legends or which should reasonably be understood to be confidential given the nature of the information and the circumstances surrounding its disclosure, including without limitation, the System, information contained or embodied in the System and non-public User Information supplied by the Customer or Cialfo (the information of a Party or its affiliates falling within the foregoing, referred to as the “Information” of such Party). The receiving party will not use any Information of the disclosing party except for the benefit of the disclosing party and only to the extent necessary to provide or use the Subscription Service, for legal or regulatory compliance or except as otherwise permitted under this Agreement. The disclosing party will treat the Information of the other Party with the same degree of care as its employs with regard to its own confidential information of a like nature and in any event in accordance with commercially reasonable practices. This Section 9 will not apply to the extent Information was rightfully in the possession of the receiving party prior to the disclosure by the disclosing party, or which is already public knowledge or lawfully becomes so at future date (other than as a result of a breach of this Section), or which is rightfully obtained by the receiving party from a third party without restrictions on disclosure.</p>
          <p>9.2 A Party shall not without the prior written consent of the other Party divulge any part of the Information of such other Party to any person except:</p>
          <p>(a) To such Party’s or such Party’s affiliates’ employees and contractors and then only to those employees and contractors who need to know the same;</p>
          <p>(b) To their legal, financial and auditing advisors bound to duties of confidentiality;</p>
          <p>(c) To a court of competent jurisdiction, governmental body or applicable regulatory authority and any other persons or bodies having a right, duty or obligation to know the business of the other Party and then only in pursuance of such right duty or obligation so long as the receiving party provides advance notice to the disclosing party as promptly as possible and cooperates with the disclosing party’s efforts to obtain a protective order regarding such disclosure.</p>
          <p>9.3 Both Parties undertake to ensure that persons and bodies referred to in Section 9.2 are made aware before the disclosure of any part of the Information that the same is confidential and that they owe a duty of confidence to the other Party.</p>
          <p>9.4 Each Party to this Agreement shall promptly notify the other Party if it becomes aware of any breach of confidence by any person to whom it divulges all or any part of the Information and shall give the other Party all reasonable assistance in connection with any proceedings which the other Party may institute against such person for breach of confidence.</p>
          <p>9.5 The foregoing obligations as to confidentiality shall remain in full force and effect notwithstanding any termination of this Agreement.</p>

          <h5 class="text-uppercase"><strong>10. TERMINATION AND SUSPENSION</strong></h5>

          <p>10.1 Either Party may terminate this Agreement by giving notice in writing to the other Party in the event:</p>
          <p>(a) the other Party commits any material breach of any term of this Agreement and shall have failed, within thirty (30) after (or in the case of non-payment, within ten (10) days of the receipt of a notice in writing from the other Party to do so, to remedy the breach (such request to contain a warning of such Party’s intention to terminate); if the a breach is not capable of remedy within the time periods noted above, then the Party in breach shall demonstrate that it has undertaken reasonable steps to cure the breach and shall then have a reasonably longer period of time to accomplish a cure; or</p>
          <p>(b) to the fullest extent permitted by law, the other Party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, liquidation or assignment for the benefit of creditors.</p>

          <p>10.2 Cialfo may terminate this Agreement with thirty (30) days’ written notice to Customer in the event there is a change in or new interpretation of applicable law or regulation that imposes substantial new or additional requirements for the protection, processing, transfer or collection of any User Information, or that limits its ability to provide the Subscription Service in the manner typically offered.</p>

          <p>10.3 The Parties agree that:</p>

          <p>(a) Except to the extent terminated earlier as provided in this Agreement, this Agreement shall end on the relevant expiration date.</p>

          <p>(b) Automatically upon termination of this Agreement, the Use Right will terminate and Customer and Users shall no longer have the right to access or use the System, and will cease such access and use and, if requested by Cialfo, will certify in writing to Cialfo that such access and use has ceased.</p>

          <p>(c) Upon termination of this Agreement for any reason, the Customer shall take steps to ensure Users are aware that access to the System is no longer available.</p>

          <p>10.4 Before termination of this Agreement for any reason, Customer shall download a copy of the User Information and any other data of Customer using the means provided for doing so by the System (or shall require that Users do the same with respect to their own information).  Typically within seven (7) days of Agreement termination (but otherwise promptly upon termination), Cialfo will similarly purge from the System the User Information (except to the extent User Information is in archival or back-up storage where it is unduly burdensome to identify or retrieve such information, in which case such information will be retained until deleted in accordance with Cialfo’s record retention and deletion policies, and treated with confidentiality until then).  Customer is responsible for downloading a copy of any data it wishes to retain post-termination of this Agreement by the termination date.  Each party shall be entitled to retain upon termination of this Agreement its working files regarding this Agreement. Cialfo has the right to retain and continue to use all de-identified and/or aggregated User Information for the purposes set forth in Section 2.7 above.</p>

          <p>10.5 Any termination of this Agreement (howsoever occasioned) shall not affect any accrued rights or liabilities of either Party nor shall it affect the coming into force or the continuance in force of any provision in this Agreement which is expressly or by implication intended to come into or continue in force on or after such termination (including without limitation provisions regarding confidentiality, proprietary rights and fees payable).</p>

          <p>10.6 Cialfo may, with notice to Customer (except in case of a security emergency), suspend the Customer’s or specific Users’ access to any or all of the System if:</p>

          <p>(a) usage of the System by the Customer is or likely to be in violation of any laws, rules and/or regulations;</p>

          <p>(b) repeated instances of posting or uploading materials that infringe or is alleged to infringe intellectual property rights belonging to others;</p>

          <p>(c) failure on the part of the Customer to make payment to Cialfo; or</p>

          <p>(d) Cialfo, in its absolute discretion, considers it appropriate to suspend usage of the System by the Customer in order to preserve the integrity and/or proper functioning or security of the System or the User Information.</p>
          <p>For the avoidance of doubt, Cialfo shall be under no obligation to refund any amount paid by the Customer in respect of the period of suspension.</p>

          <h5 class="text-uppercase"><strong>11. RELATIONSHIP OF THE PARTIES</strong></h5>

          <p>This Agreement shall not constitute or imply any partnership, joint venture, agency, fiduciary relationship or other relationship between the Parties other than the contractual relationship expressly provided for in this Agreement.</p>

          <h5 class="text-uppercase"><strong>12. AMENDMENTS</strong></h5>

          <p>12.1 Cialfo may unilaterally amend this Agreement by giving notice to the Customer. Except as provided in Section 12.2, the amendment will take effect on the date the notice is given by Cialfo to the Customer.</p>
          <p>12.2 If the Customer is unwilling for the Agreement to continue as amended, it may terminate the Agreement by giving notice to Cialfo within three (3) business days from the date the notice is given by Cialfo to the Customer.</p>

          <h5 class="text-uppercase"><strong>13. ASSIGNMENT</strong></h5>

          <p>This Agreement nor any rights or obligations under it, may be assigned or transferred by Customer without the prior written approval of Cialfo, not to be unreasonably withheld. Any attempted assignment in violation of this Clause will be void and without effect. Cialfo may assign or transfer this Agreement to an affiliate or to a successor or buyer or all or substantially all of its business that relates to this Agreement or the System.  Any permitted assignee or transferee shall be bound to the terms of this Agreement.</p>

          <h5 class="text-uppercase"><strong>14. ENTIRE AGREEMENT</strong></h5>

          <p>This Agreement supersedes all prior agreements, arrangements and undertakings between the Parties and constitutes the entire agreement between the Parties relating to the subject matter of this Agreement. However, the obligations of the Parties under any pre-existing non-disclosure agreement shall remain in full force and effect in so far as there is no conflict between the same. The Parties confirm that they have not entered into this Agreement on the basis of any representation that is not expressly incorporated into this Agreement.</p>

          <h5 class="text-uppercase"><strong>15. NOTICES</strong></h5>

          <p>15.1 All notices under this Agreement shall be in writing.</p>

          <p>15.2 Notices shall be deemed to have been duly given:</p>

          <p>(a) When delivered, if hand-delivered by courier or other messenger during normal business hours of the recipient; or</p>

          <p>(b) When sent, if transmitted by e-mail and a successful transmission report or return receipt is generated; or</p>

          <p>(c) On the date of receipt if sent by ordinary mail, postage prepaid, certified return receipt requested, or if mailed by airmail, postage prepaid.</p>

          <p>In each case addressed to the most recent address, or e-mail address notified to the other Party. In addition to a copy by another means identified above, all legal notices to Cialfo relating to the Agreement must also be send to notices@cialfo.com.sg.</p>

          <h5 class="text-uppercase"><strong>16. SEVERANCE</strong></h5>

          <p>If any provision of this Agreement is prohibited by law or judged by a court to be unlawful, void or unenforceable, the provision shall, to the extent required be severed from this Agreement and rendered ineffective as far as possible without modifying the remaining provision of this Agreement, and shall not in any way affect any other circumstances of or the validity or enforcement of this Agreement.</p>

          <h5 class="text-uppercase"><strong>17. SUCCESSORS AND ASSIGNEES</strong></h5>

          <p>17.1 Subject to Section 13 above, this Agreement shall be binding upon, and inure to the benefit of, the Parties and their respective successors and permitted assignees, and references to a Party in this Agreement shall include its successors and permitted assignees.</p>
          <p>17.2 Subject to Section 13 above, in this Agreement references to a Party include references to a person:</p>
          <p>(a) Who for the time being is entitled (by assignment, novation or otherwise) to that Party’s rights under this Agreement (or any interest in those rights); or</p>
          <p>(b) Who, as administrator, liquidator or otherwise, is entitled to exercise those rights, and in particular those references include a person to whom those rights (or any interest in those rights) are transferred or pass as a result of a merger, division, reconstruction or other reorganization involving that Party. For this purpose, references to a Party’s rights under this Agreement include any similar rights to which another person becomes entitled as a result of a novation of this Agreement.</p>

          <h5 class="text-uppercase"><strong>18. WAIVER; REMEDIES</strong></h5>

          <p>No delay, neglect or forbearance on the part of either Party in enforcing against the other Party any term or condition of this Agreement shall either be or deemed to be a waiver or in any way prejudice any right of that Party under this Agreement. No right, power or remedy in this Agreement conferred upon or reserved for either Party is exclusive of any other right, power or remedy available to that Party.</p>

          <h5 class="text-uppercase"><strong>19. INTERPRETATION. Unless the context otherwise requires:</strong></h5>

          <p>(a) words importing the singular include the plural and vice versa, words importing any gender include every gender, words importing persons include bodies corporate and unincorporate and references to time shall mean United States Eastern Time;</p>
          <p>(b) Section headings are for convenience of reference only and shall not affect the interpretation of this Agreement; and</p>
          <p>(c) Attachments and recitals are an integral part of this Agreement and have the same force and effect as if expressly set out in the body of this Agreement and reference to this Agreement includes reference to all the aforesaid.</p>

          <h5 class="text-uppercase"><strong>20. FORCE MAJEURE</strong></h5>

          <p>Neither party will be liable to the other party for any failure or delay in fulfilling or performing an obligation of this Agreement (other than a payment obligation or a failure to comply with applicable laws or to obtain required consents), to the extent such failure or delay is caused by acts or events beyond the affected party's reasonable control, including, without limitation,  acts of God,  flood, fire,  national or regional emergency, externally introduced malware, terrorism, cyber-attacks, acts of nature, events impacting the Internet, or shortage or interruption of power or telecommunications or transportation facilities. A party whose performance is affected by such events shall promptly give notice to the other party and shall use reasonable efforts to minimize the effects on the other party.</p>

          <h5 class="text-uppercase"><strong>21. EXECUTION IN COUNTERPARTS</strong></h5>

          <p>This Agreement may be signed and delivered in any number of counterparts, all of which taken together and when delivered to the Parties by facsimile or by electronic mail in "portable document format (".pdf") form, or by any other electronic means intended to preserve the original graphic and pictorial appearance of a document, or by a combination of such means, shall constitute one and the same instrument. Any Party may enter into this Agreement by manually signing any such counterpart transmitted electronically or by facsimile, email or other electronic signature by any of the Parties to any other Party and the receiving Party may rely on the receipt of such document so executed and delivered by facsimile, email or other electronic means as if the original had been received. Such signatures executed or delivered by way of facsimile, email or other electronic means shall be recognized and construed as secure electronic signatures pursuant to applicable electronic transactions and e-sign laws and the Parties shall deem such signatures to be original signatures for all purposes.</p>

          <h5 class="text-uppercase"><strong>22. COST AND EXPENSES</strong></h5>

          <p>Each Party shall bear its own legal costs and other costs and expenses arising in connection with the drafting, negotiation, execution and registration (if applicable) of this Agreement.</p>

          <h5 class="text-uppercase"><strong>23. GOVERNING LAW AND JURISDICTION</strong></h5>

          <p>This Agreement shall be governed by and construed according to the laws of the State of Delaware, excluding the application of its conflicts of laws provisions. Each Party hereby submits to the exclusive jurisdiction of the Delaware courts for matters relating to the interpretation and enforcement of this Agreement.</p>

          <h5 class="text-uppercase"><strong>24. FURTHER ASSURANCES</strong></h5>

          <p>The Parties shall execute and do and procure all other persons if necessary, to execute and do all such further deeds, assurances, acts and things as may be reasonably required so that full effect may be given to the terms and conditions of this Agreement.</p>
        </div>
      </Container>
    );
  }
}

export default withRouter(TermsOfServiceUS);


