import React from "react";
import { withRouter } from "react-router-dom";
import './GetStarted.css';

class GetStarted extends React.Component {
  handleClick = () => {
    const email = this.email.value;
    this.props.sendEmailAddressToGetADemo(email);
    this.props.history.push('/demo');
  }

  render() {
    return (
      <div className="getStartedWrapper d-flex justify-content-center">
        <input ref={r => this.email = r} type="text" className="email" placeholder={this.props.placeholder} />
        <button className="getStarted" onClick={this.handleClick}>{this.props.label}</button>
      </div>
    )
  }
}

export default withRouter(GetStarted);
