export const contenfulConfig = {
  environment: "master",
  chinaSpace: {
    space: "1acwuo4zy8aa",
    accessToken:
      "c6080034f52655b2fdb9267c7c555bff17c0134a4ae75b646bb112d992b485b2",
    spaceName: "china"
  },
  indiaSpace: {
    space: "6s1t375h60iy",
    accessToken: "vZ4pPEGukFHPrZCLU0ql6SlH5hvabD-aAV2wr65Pjwo",
    spaceName: "india"
  },
  internationalSpace: {
    space: "kq0n6h3xq8i9",
    accessToken: "9tSaFiRLObn_CKT5hpYU-iNrTN47rUquWSmSfV3KNLY",
    spaceName: "intl"
  },
  usaSpace: {
    space: "2w8l1bcem16l",
    accessToken: "bO1jaDXJM1S5kWXDVJoZ6buysg9bGkhohqYyJr-NxIw",
    spaceName: "us"
  },
  sharedSpace: {
    space: "6z68mehe72kf",
    accessToken: "yuqqUFmLi3xxrPn472eQQJP5Qe5hcjxZahdXKfdNnrc",
    spaceName: "shared"
  }
};
