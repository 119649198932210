import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import LinkedInBug from "../../img/LinkedInBug.png";

class MobileAboutCounselors extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const counselorsObject = this.props.counselors;
    let counselorsObjectObject;

    if (counselorsObject) {
      counselorsObjectObject = counselorsObject.map(counselorsObject => {
        return (
          <Container
            key={counselorsObject.sys.id}
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              minHeight: "200px"
            }}
            className="light-blue-background counselor-advisor-container  mx-4"
            style={{ height: "100%" }}
          >
            <div
              className=" counselor-advisor-container-inner-object"
              style={{ width: "100%" }}
            >
              <Row className="height-80 mb-2">
                {counselorsObject.fields.aboutPageBusinessAdvisorImage && (
                  <Image
                    roundedCircle
                    className="max-height-width-100"
                    src={
                      counselorsObject.fields.aboutPageBusinessAdvisorImage
                        .fields.file.url
                    }
                  />
                )}
              </Row>
              <Row>
                <div>
                  <p className="secondary_font_bold">
                    {counselorsObject.fields.aboutPageBusinessAdvisorTitle}
                    {counselorsObject.fields.aboutCounselorsBizLinkedIn && (
                      <a
                        target="_blank"
                        href={
                          counselorsObject.fields.aboutCounselorsBizLinkedIn
                        }
                        className="ml-2"
                      >
                        <img
                          alt="linkedin"
                          className="linked-in-icon mb-1"
                          src={LinkedInBug}
                        />
                      </a>
                    )}
                  </p>
                </div>
              </Row>
              <Row>
                <p className="secondary_font reduced-margin-bottom">
                  {counselorsObject.fields.organization}
                </p>
              </Row>
              <Row>
                <p className="secondary_font">
                  {counselorsObject.fields.jobTitle}
                </p>
              </Row>
            </div>
          </Container>
        );
      });
    }
    return <Row>{counselorsObjectObject}</Row>;
  }
}

export default MobileAboutCounselors;
