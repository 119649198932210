import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./About.css";
import Container from "react-bootstrap/Container";
import LinkedInBug from "../../img/LinkedInBug.png";

class MobileAboutBusinessAdvisors extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const businessAdvisors = this.props.businessAdvisors;
    let businessAdvisorsObject;

    if (businessAdvisors) {
      businessAdvisorsObject = businessAdvisors.map(businessAdvisor => {
        return (
          <Container
            key={businessAdvisor.sys.id}
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              minHeight: "200px"
            }}
            className="counselor-advisor-container light-blue-background mx-4"
            style={{ height: "100%" }}
          >
            <div
              className="counselor-advisor-container-inner-object"
              style={{ width: "100%" }}
            >
              <Row className="height-80 mb-2">
                {businessAdvisor.fields.aboutPageBusinessAdvisorImage && (
                  <Image
                    className="max-height-width-100"
                    roundedCircle
                    src={
                      businessAdvisor.fields.aboutPageBusinessAdvisorImage
                        .fields.file.url
                    }
                  />
                )}
              </Row>
              {businessAdvisor.fields.aboutBizCompanyLinkedIn && (
                <Row>
                  <div className="about-biz-image-div">
                    <div className="about-biz-image-col">
                      <a
                        target="_blank"
                        href={businessAdvisor.fields.aboutCounselorsBizLinkedIn}
                      >
                        <img
                          alt="linkedin"
                          className="biz-linked-in-icon mb-1"
                          src={
                            businessAdvisor.fields.aboutBizCompanyLogo.fields
                              .file.url
                          }
                        />
                      </a>
                    </div>
                    <div className="about-biz-title-col">
                      <Row>
                        <p className="secondary_font mb-0">
                          {businessAdvisor.fields.organization}
                        </p>
                      </Row>
                      <Row>
                        <p className="secondary_font mb-0">
                          {businessAdvisor.fields.jobTitle}
                        </p>
                      </Row>
                    </div>
                  </div>
                </Row>
              )}
              {!businessAdvisor.fields.aboutBizCompanyLinkedIn && (
                <div>
                  <Row>
                    <p className="secondary_font mb-0">
                      {businessAdvisor.fields.organization}
                    </p>
                  </Row>
                  <Row>
                    <p className="secondary_font">
                      {businessAdvisor.fields.jobTitle}
                    </p>
                  </Row>
                </div>
              )}
            </div>
          </Container>
        );
      });
    }
    return <Row>{businessAdvisorsObject}</Row>;
  }
}

export default MobileAboutBusinessAdvisors;
