import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Comparison from "./components/Comparison";
import { withRouter } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext"

class CialfoVSMaia extends React.Component {

  static contextType = DataContext;
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    this.context.fetchEntries("comparisonPage").then((response) => {
      let data = this.context.setContent(response, "cialfoVSMaia")
      this.setState({
        data: data
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.locale !== this.props.match.params.locale) {
      this.context.fetchEntries("comparisonPage").then((response) => {
        let data = this.context.setContent(response, "cialfoVSMaia")
        this.setState({
          data: data
        })
      });
    }
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Comparison
            getADemoEmail={this.props.getADemoEmail}
            sendEmailAddressToGetADemo={this.props.sendEmailAddressToGetADemo}
            data={this.state.data}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(CialfoVSMaia);
