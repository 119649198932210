import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import "./About.css";
import LinkedInBug from "../../img/LinkedInBug.png";

class AboutCounselors extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const counselorsObject = this.props.counselors;
    let counselorsObjectObject;

    if (counselorsObject) {
      counselorsObjectObject = counselorsObject.map(counselorsObject => {
        return (
          <Col
            xs={12}
            sm={6}
            md={6}
            xl={4}
            key={counselorsObject.sys.id}
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              minHeight: "200px"
            }}
          >
            <Container
              className="counselor-advisor-container light-blue-background"
              style={{ height: "100%" }}
            >
              <div
                className="counselor-advisor-container-inner-object"
                style={{ width: "100%" }}
              >
                <Row className="height-80 mb-2">
                  {counselorsObject.fields.aboutPageBusinessAdvisorImage && (
                    <Image
                      className="max-height-width-100"
                      roundedCircle
                      src={
                        counselorsObject.fields.aboutPageBusinessAdvisorImage
                          .fields.file.url
                      }
                    />
                  )}
                </Row>
                <Row>
                  <div>
                    <p className="secondary_font_bold mb-2">
                      {counselorsObject.fields.aboutPageBusinessAdvisorTitle}
                      {counselorsObject.fields.aboutCounselorsBizLinkedIn && (
                        <a
                          target="_blank"
                          href={
                            counselorsObject.fields.aboutCounselorsBizLinkedIn
                          }
                          className="ml-2"
                        >
                          <img
                            alt="linkedin"
                            className="linked-in-icon mb-1"
                            src={LinkedInBug}
                          />
                        </a>
                      )}
                    </p>
                  </div>
                </Row>
                <Row>
                  <p className="secondary_font mb-0">
                    {counselorsObject.fields.organization}
                  </p>
                </Row>
                <Row>
                  <p className="secondary_font">
                    {counselorsObject.fields.jobTitle}
                  </p>
                </Row>
              </div>
            </Container>
          </Col>
        );
      });
    }
    return <Row>{counselorsObjectObject}</Row>;
  }
}

export default AboutCounselors;
