import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router-dom";
import { DataContext } from "../../contexts/DataContext"

import './Partners.css';
import GetStarted from "../sharedComponents/GetStarted";

class Partners extends React.Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    this.context.fetchEntries("homePageHeaderProductImage").then((response) => {
      let data = this.context.setContent(response, "partnersPage")
      data.partnerItems = data.partnerItems.map(item => this.context.setContent([item]));
      this.setState({
        data: data
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.locale !== this.props.match.params.locale) {
      this.context.fetchEntries("homePageHeaderProductImage").then((response) => {
        let data = this.context.setContent(response, "partnersPage")
        data.partnerItems = data.partnerItems.map(item => this.context.setContent([item]));
        this.setState({
          data: data
        })
      });
    }
  }

  generateRow = (startIndex, cols) => {
    const { data } = this.state;
    let table = []
    for(var i=startIndex; i<startIndex+cols; i++){
      if (data.partnerItems[i]) {
        table.push(
          <div key={i} className="partnerItem">
            <img src={data.partnerItems[i].partnerItemLogo} className="partnerLogo" alt="logo" />
            <div className="partnerInfo">
              {data.partnerItems[i].partnerItemTag &&
                <div className="partnerTag">{data.partnerItems[i].partnerItemTag}</div>}
              <h4 className="partnerTitle">
                {data.partnerItems[i].header}
              </h4>
              <p className="partnerDescription">
                {data.partnerItems[i].content}
              </p>
            </div>
          </div>)
      } else {
        table.push(<div key={i} className="partnerItem"></div>)
      }
    }
    return <div key={startIndex} className="partnerItemsGroup">{table}</div>;
  }

  generateTable = (cols) => {
    const { data } = this.state;
    if (!data.partnerItems) {
      return null;
    }
    let table = []

    for(var i=0; i<data.partnerItems.length; i+=cols){
      table.push(this.generateRow(i, cols))
    }

    return table;
  }

  render() {
    const { data } = this.state;
    return (
      <Container fluid>
        <Row className="partnersPageHeader">
          <h1 className="partnersPageHeaderTitle">{data.homePageHeaderTitle}</h1>
          <p className="partnersPageHeaderDescription">{data.homePageHeaderBlurb}</p>
          <GetStarted
            sendEmailAddressToGetADemo={this.props.sendEmailAddressToGetADemo}
            placeholder={data.homePageHeaderEmailPlaceholderText}
            label={data.homePageHeaderEmailSubmitButtonText}
          />
          <div className="partnersPageHeaderArc" />
        </Row>
        <Row className="partnersContainer">
          <MediaQuery query="(min-width: 1224px)">
            {this.generateTable(3)}
          </MediaQuery>
          <MediaQuery query="(max-width: 1223px) and (min-width: 768px)">
            {this.generateTable(2)}
          </MediaQuery>
          <MediaQuery query="(max-width: 767px)">
            {this.generateTable(1)}
          </MediaQuery>
        </Row>
      </Container >
    );
  }
}

export default withRouter(Partners);
