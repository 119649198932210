import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { withRouter, Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import './ComparisonItem.css';

class ComparisonItem extends React.Component {
  render() {
    const {
      left,
      title,
      description,
      order,
      image,
      linkLabel,
      link,
    } = this.props;
    const itemInfo = (
      <Col className="d-flex align-items-center">
        <Row>
          <span className="itemOrder">{order}</span>
          <h1 className="itemTitle">{title}</h1>
          <p className="itemDescription">{description}</p>
          {linkLabel && (<Link
            className="itemLink"
            to={link}>
            {linkLabel}
          </Link>)}
        </Row>
      </Col>
    );
    const itemImage = (
      <Col className="comparisonItemImageWrapper">
        <Row>
          <img src={image} width="100%" height="auto" />
        </Row>
      </Col>
    );
    return (
      <Row className="comparisonItem">
        <MediaQuery query="(min-width: 1224px)">
          {left ? itemImage : itemInfo}
          {!left ? itemImage : itemInfo}
        </MediaQuery>
        <MediaQuery query="(max-width: 1223px)">
          {itemImage}
          {itemInfo} 
        </MediaQuery>
      </Row>
    );
  }
}

export default withRouter(ComparisonItem);
