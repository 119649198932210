import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { withRouter, Link } from "react-router-dom";
import DemoCallToAction from "../sharedComponents/DemoCallToAction";
import Stroke from '../../img/Stroke12.svg';
import Line from '../../img/Line.svg';

import './SolutionsSubPage.css';

class SolutionsSubPage extends React.Component {
  renderSolutionItem = (solItem, index) => {
    return (
      <Row key={index} className={`solutionItem ${solItem.solutionItemPartnersTitle ? 'featured' : ''}`}>
        <div className="d-flex align-items-center rtl">
          <img className="solutionItemImg" src={solItem.solutionItemImage} alt="" />
          <div className="solutionItemInfo">
            <p className="solutionItemTag">{solItem.solutionItemTag}</p>
            <h1 className="solutionItemTitle">{solItem.header}</h1>
            <p className="solutionItemDescription">{solItem.solutionItemDescription}</p>
            {solItem.solutionSubItemTitle1 && (<div className="solutionItemSubItem">
              <p className="solutionItemSubItemTitle">{solItem.solutionSubItemTitle1}</p>
              <p className="solutionItemDescription">{solItem.solutionSubItemDescription1}</p>
            </div>)}
            {solItem.solutionSubItemTitle2 && (<div className="solutionItemSubItem">
              <p className="solutionItemSubItemTitle">{solItem.solutionSubItemTitle2}</p>
              <p className="solutionItemDescription">{solItem.solutionSubItemDescription2}</p>
            </div>)}
            {solItem.solutionSubItemTitle3 && (<div className="solutionItemSubItem">
              <p className="solutionItemSubItemTitle">{solItem.solutionSubItemTitle3}</p>
              <p className="solutionItemDescription">{solItem.solutionSubItemDescription3}</p>
            </div>)}
          </div>
        </div>
        {solItem.solutionItemPartnersTitle && (
          <div className="solutionItemPartnersPanel">
            <p className="solutionItemPartnersPanelTitle">{solItem.solutionItemPartnersTitle}</p>
            <div className="solutionItemPartnersGroup">
              {
                solItem.solutionItemPartnersImages.map(img => (
                  <div key={img} className="solutionItemPartner" style={{backgroundImage: `url(${img})`}}></div>
                ))
              }
            </div>
          </div>
        )}
      </Row>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <Container fluid className="no-padding">
        <Row className="solutionsSubPageHeader">
          <span className="solutionsSubPageHeaderTag">{data.solSubHeaderTag}</span>
          <h1 className="solutionsSubPageHeaderTitle">{data.homePageHeaderTitle}</h1>
          <p className="solutionsSubPageHeaderDescription">{data.homePageHeaderBlurb}</p>
          <p className="solutionsUsageTitle">{data.solSubUsageTitle}</p>
          {data.solSubUsageItems && <div className="solutionsUsageItemsGroup d-flex">
            <div className="solutionsUsageItems">
              <div className="solutionsUsageItem">{data.solSubUsageItems[0]}</div>
              <div className="solutionsUsageItem">{data.solSubUsageItems[1]}</div>
            </div>
            <div className="solutionsUsageItems">
              <div className="solutionsUsageItem">{data.solSubUsageItems[2]}</div>
              <div className="solutionsUsageItem">{data.solSubUsageItems[3]}</div>
            </div>
          </div>}
          <Link
            to="/demo"
            className="getStarted"
          >
            {data.homePageHeaderEmailSubmitButtonText}
          </Link>
          <img src={Stroke} className="stroke" alt="" />
          <img src={Line} className="line" alt="" />
        </Row>

        {data.solSubSolutionItems && data.solSubSolutionItems.map((item, idx) => this.renderSolutionItem(item, idx))}
        <DemoCallToAction />
      </Container>
    );
  }
}

export default withRouter(SolutionsSubPage);
