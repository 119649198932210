import React from "react";
import HomeMarquee from "./home/HomeMarquee";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import MediaQuery from "react-responsive";
import { DataContext } from "../contexts/DataContext";
import { withRouter, Link } from "react-router-dom";
import DemoCallToAction from "./sharedComponents/DemoCallToAction";
import GetStarted from "./sharedComponents/GetStarted";

import Stroke from "../img/Stroke10.svg";
import BlueOval from "../img/BlueOval.svg";
import Line from "../img/Line.svg";

import "./solutions/solutions.css";

class Solutions extends React.Component {
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    this.context.fetchEntries("fourOhFourSolutions").then(response => {
      let data = this.context.setContent(response, "solutions");
      this.context.fetchEntries("marqueeItem").then(response => {
        let contentGroups = this.context.setMarqueeContent(
          response,
          "marqueeItem",
          "solutionsContentGroup"
        );
        contentGroups = data.solutionsContentGroups.map(contentGroup => {
          let id = contentGroup.sys.id;
          let index = contentGroups.findIndex(group => group.sys.id === id);
          return contentGroups[index];
        });
        contentGroups = contentGroups.map(contentGroup =>
          this.context.setContent([contentGroup], "solutionsContentGroup")
        );
        contentGroups.map(contentGroup => {
          if (contentGroup.solutionsContentGroupItems) {
            contentGroup.solutionsContentGroupItems = contentGroup.solutionsContentGroupItems.map(
              item => this.context.setContent([item], "solutionsContentItem")
            );
          } else {
            contentGroup.solutionsContentGroupItems = [];
          }
        });
        data.solutionsContentGroups = contentGroups;
        this.setState({
          data: data
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.locale !== this.props.match.params.locale) {
      this.context.fetchEntries("fourOhFourSolutions").then(response => {
        let data = this.context.setContent(response, "solutions");
        this.context.fetchEntries("marqueeItem").then(response => {
          let contentGroups = this.context.setMarqueeContent(
            response,
            "marqueeItem",
            "solutionsContentGroup"
          );
          contentGroups = data.solutionsContentGroups.map(contentGroup => {
            let id = contentGroup.sys.id;
            let index = contentGroups.findIndex(group => group.sys.id === id);
            return contentGroups[index];
          });
          contentGroups = contentGroups.map(contentGroup =>
            this.context.setContent([contentGroup], "solutionsContentGroup")
          );
          contentGroups.map(contentGroup => {
            if (contentGroup.solutionsContentGroupItems) {
              contentGroup.solutionsContentGroupItems = contentGroup.solutionsContentGroupItems.map(
                item => this.context.setContent([item], "solutionsContentItem")
              );
            }
          });
          data.solutionsContentGroups = contentGroups;
          this.setState({
            data: data
          });
        });
      });
    }
  }

  generateRow = (tabId, start, count) => {
    const row = [];

    for (var i = start; i < start + count; i++) {
      row.push(
        <Col key={i} className="solutionsContentItem">
          {this.state.data.solutionsContentGroups[tabId]
            .solutionsContentGroupItems[i] && (
            <Row>
              <h1 className="solutionsContentItemTitle">
                {
                  this.state.data.solutionsContentGroups[tabId]
                    .solutionsContentGroupItems[i].solutionsContentItemTitle
                }
              </h1>
              <p className="solutionsContentItemDescription">
                {
                  this.state.data.solutionsContentGroups[tabId]
                    .solutionsContentGroupItems[i]
                    .solutionsContentItemDescription
                }
              </p>
              {this.state.data.solutionsContentGroups[tabId]
                .solutionsContentGroupItems[i]
                .solutionsContentItemLinkLabel && (
                <Link
                  className={
                    "solutionsContentItemLink homeFeatureLink " +
                    [
                      "homePageFeaturesSendDocumentLinkText",
                      "homePageFeaturesLeverageLinkText",
                      "homePageFeaturesDiscoverLinkText"
                    ][i % 3]
                  }
                  to={
                    this.state.data.solutionsContentGroups[tabId]
                      .solutionsContentGroupItems[i].solutionsContentItemLink ||
                    "home"
                  }
                >
                  {
                    this.state.data.solutionsContentGroups[tabId]
                      .solutionsContentGroupItems[i]
                      .solutionsContentItemLinkLabel
                  }
                </Link>
              )}
            </Row>
          )}
        </Col>
      );
    }
    return row;
  };

  generateTable = (tabId, limit, cols) => {
    const table = [];

    for (var i = 0; i < limit; i += cols) {
      table.push(
        <Row key={i} className="solutionsContentItemsRow">
          {this.generateRow(tabId, i, cols)}
        </Row>
      );
    }
    return table;
  };

  renderContents = (tabId, limit) => {
    return (
      <Col>
        <MediaQuery query="(min-width: 1224px)">
          {this.generateTable(tabId, limit, 3)}
        </MediaQuery>
        <MediaQuery query="(max-width: 1223px) and (min-width: 768px)">
          {this.generateTable(tabId, limit, 2)}
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          {this.generateTable(tabId, limit, 1)}
        </MediaQuery>
      </Col>
    );
  };

  render() {
    return (
      <Container className="solutionsPageContainer" fluid>
        <Row className="solutionsPageHeader d-flex flex-column text-center">
          <h1 className="solutionsPageHeaderTitle">
            {this.state.data.solutionsHeaderTitle}
          </h1>
          <p className="solutionsPageHeaderDescription">
            {this.state.data.solutionsHeaderDescription}
          </p>
          <GetStarted
            sendEmailAddressToGetADemo={this.props.sendEmailAddressToGetADemo}
            placeholder={this.state.data.solutionsGetStartedPlaceholder}
            label={this.state.data.solutionsGetStartedButton}
          />
          <img src={Stroke} className="img-stroke" alt="" />
          <img src={BlueOval} className="img-circle" alt="" />
          <img src={Line} className="img-line" alt="" />
        </Row>

        <Row className="solutionsContentsContainer flex-column">
          <div className="solutionsContentsContainerTop" />

          {this.state.data.solutionsContentGroups && (
            <Tabs
              transition={false}
              variant="pills"
              className="solutionsContentsHeader"
            >
              {this.state.data.solutionsContentGroups.map(
                (contentGroup, index) => (
                  <Tab
                    key={index}
                    eventKey={contentGroup.solutionsContentGroupTitle}
                    title={contentGroup.solutionsContentGroupTitle}
                  >
                    {this.renderContents(
                      index,
                      contentGroup.solutionsContentGroupItems.length
                    )}
                    {contentGroup.solutionsContentGroupButton && (
                      <div className="d-flex">
                        <Link
                          className="solutionsContentsExploreAllBtn mx-auto"
                          to={contentGroup.solutionsContentGroupLink}
                        >
                          {contentGroup.solutionsContentGroupButton}
                        </Link>
                      </div>
                    )}
                  </Tab>
                )
              )}
            </Tabs>
          )}
        </Row>

        <MediaQuery query="(min-width: 768px)">
          <Row className="homeMarquee">
            <h1 className="marqueeText text-center">
              {this.state.data.solutionsTestimonialTitle}
            </h1>
            <HomeMarquee
              locale={this.props.locale}
              accessToken={this.props.accessToken}
              space={this.props.space}
              spaces={this.props.spaces}
              setSpace={this.props.setSpace}
              setAccessToken={this.props.setAccessToken}
              environment={this.props.environment}
            />
          </Row>
        </MediaQuery>
        <DemoCallToAction />
      </Container>
    );
  }
}

export default withRouter(Solutions);
