import Row from "react-bootstrap/Row";
import React from "react";
import "./getADemo/GetADemo.css";
import "./privacyAndSecurity/privacyAndSecurity.css";
import Container from "react-bootstrap/Container";
import { withRouter } from "react-router-dom";

class Subprocessors extends React.Component {
  render() {
    return (
      <Container className="secondary_font pt-5">
        <Container class="text-lg-center text-md-center text-sm-center text-xs-center">
          <Row className="center-in-row">
            <p className="hidden-xs-down primary_font privacy-upper-title">
              Cialfo
            </p>
          </Row>
          <Row className="center-in-row">
            <h1 className="primary_font terms-of-service-title ">
              Subprocessors
            </h1>
          </Row>
          <br />
        </Container>
        <Row>
          <Container class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p><strong>Last updated: 13 September, 2018</strong></p>
            <p>Cialfo Pte Ltd. ("Cialfo") uses certain subprocessors, subcontractors and content delivery networks to assist it in providing the Cialfo Services as described in the Terms and Conditions ("Terms").</p>
            <h5>What is a Subprocessor</h5>
            <p>A subprocessor is a third party data processor engaged by Cialfo who has or potentially will have access to or process Service Data (which may contain Personal Data). Cialfo engages different types of subprocessors to perform various functions as explained in the tables below. Cialfo refers to third parties that do not have access to or process Service Data but who are otherwise used to provide the Services as “subcontractors” and not subprocessors.</p>
            <h5>Due Diligence</h5>
            <p>Cialfo undertakes a commercially reasonable selection process by which it evaluates the security, privacy and confidentiality practices of proposed subprocessors that will or may have access to or process Service Data.
            </p><h5>Infrastructure Subprocessors</h5>
            <p>Cialfo owns or controls access to the infrastructure that Cialfo uses to host Service Data submitted to the Services, other than as set forth below. The following table describes the countries and legal entities engaged in the storage of Service Data by Cialfo.</p>
            <Container class="table-responsive">
              <table class="table">
                <tbody><tr>
                  <th>Entity Name</th>
                  <th>Subprocessing Activities</th>
                  <th>Entity Country</th>
                </tr>
                <tr>
                  <td>Amazon Web Services, Inc.</td>
                  <td>Cloud Service Provider</td>
                  <td>United States</td>
                </tr>
                <tr>
                  <td>Linode, LLC</td>
                  <td>Cloud Service Provider</td>
                  <td>United States</td>
                </tr>
              </tbody></table>
            </Container>
            <h5>Service Specific Subprocessors</h5>
            <p>Cialfo works with certain third parties to provide specific functionality within the Services. These providers are the Subprocessors set forth below. In order to provide the relevant functionality, these subprocessors access Service Data.</p>
            <Container class="table-responsive">
              <table class="table">
                <tbody><tr>
                  <th>Entity Name</th>
                  <th>Purpose</th>
                </tr>
                <tr>
                  <td>Twilio, Inc.</td>
                  <td>Twilio, Inc.'s ("Twilio") development platform provides the APIs from which Cialfo accesses the telecommunications infrastructure. Twilio has access to Customer and End-User information as needed to deliver the text messages between Subscribers and End-Users. This includes Service Data contained in the messages and the Personal Data of Customers and End-Users as needed to send and deliver the messages.</td>
                </tr>
                <tr>
                  <td>Cloudflare, Inc.</td>
                  <td>Cloudflare, Inc. ("Cloudflare") provides content distribution, security, and DNS services for web traffic transmitted to and from the Services. This allows Cialfo to efficiently manage traffic and secure the Services. The primary information Cloudflare has access to is information in and associated with the Cialfo URL that the End-User is interacting with (which includes IP addresses). All information including Service Data contained in web traffic transmitted to and from the Services is transmitted through Cloudflare's systems, but Cliudflare does not have access to this information.</td>
                </tr>
                <tr>
                  <td>SendGrid, Inc.</td>
                  <td>SendGrid, Inc. ("SendGrid") provides email delivery for Cialfo. Cialfo relies on SendGrid's email APIs to power transactional and account-related emails sent to End-Users and Customers.</td>
                </tr>
                <tr>
                  <td>Mixpanel, Inc.</td>
                  <td>Mixpanel, Inc. ("Mixpanel") is the analytics provider that Cialfo uses to provide insights and analytics within the Services. Data processed includes time zone, browser, device, initial referrers, operating systems, referring domains, and last seen (the last time a property was set or updated).</td>
                </tr>
                <tr>
                  <td>Intercom, Inc.</td>
                  <td>Intercom, Inc. ("Intercom") is a cloud-based customer support service that Cialfo uses to provide customer support chat. To provide their services, Intercom has access to the following types of data: email addresses, IP addresses, account subdomains, device fingerprint, and account behaviour such as time spent on a page, number of pages visited, and number of sessions.</td>
                </tr>
              </tbody></table>
            </Container>
            <h5>Other Subcontractors</h5>
            <Container class="table-responsive">
              <table class="table">
                <tbody><tr>
                  <th>Entity Name</th>
                  <th>Subprocessing Activities</th>
                </tr>
                <tr>
                  <td>ActiveCampaign, Inc.</td>
                  <td>Cloud Email Provider</td>
                </tr>
                <tr>
                  <td>Pipedrive, Inc.</td>
                  <td>Customer Relationship Manager</td>
                </tr>
                <tr>
                  <td>Slack Technologies, Inc.</td>
                  <td>Cloud-based Communication Services</td>
                </tr>
                <tr>
                  <td>Segment, Inc.</td>
                  <td>Cloud-based Analytics Service</td>
                </tr>
                <tr>
                  <td>Stitch Inc.</td>
                  <td>Cloud-based Analytics Service</td>
                </tr>
              </tbody></table>
            </Container>
          </Container>
        </Row>
        <Row className="center-in-row pb-5">
          <h1>···</h1>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Subprocessors);
