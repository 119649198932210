import React from "react";
import Container from "react-bootstrap/Container";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ZoomCialfoImg from "../img/zoom/Zoom_and_Cialfo.png";
import CameraIcon from "../img/zoom/camera_icon.svg";
import CalendarIcon from "../img/zoom/calendar_icon.svg";
import HeadsetIcon from "../img/zoom/headset_icon.svg";
import CialfoIcon from "../img/zoom/cialfo_icon.svg";
import MediaQuery from "react-responsive";
import "./zoom/Zoom.css";

class Zoom extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth,
      subDomain: ''
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  handleClick = () => {
    const domain = this.state.subDomain;
    const domainUrl = 'https://' + domain + '.cialfo.co/?redirect_to=%2Fmanage%2Fmentor_settings%3Fzoom%3D1';
    window.open(domainUrl, '_blank');
  }

  handleChange = (event) => {
    this.setState({
      subDomain: event.target.value
    })
  }

  render() {
    return (
      <Container className="zoomPage" fluid>
        {/* FULL SCREEN HEADER */}
        <MediaQuery query="(min-device-width: 1224px)">
          {this.state.width >= 1400 && (
            <Row className="ZoomPageHeader">
              <div className="d-flex flex-column header-content">
                <h1 className="title-width primary_font">Integrate Cialfo with Zoom to streamline distance counseling</h1>
                <div className="d-flex flex-column">
                  <span className="text-black font-18 mb-11px">Enter your school's Cialfo URL</span>
                  <div className="d-flex">
                    <input value={this.state.subDomain} onChange={this.handleChange} className="form-control input" placeholder="your-cialfo-url"></input>
                    <span className="input-label">.cialfo.co</span>
                    <button className="btn-sign-in" disabled={this.state.subDomain.length == 0} onClick={this.handleClick}>Sign in to integrate</button>
                  </div>
                </div>
              </div>
            </Row>
          )}
          {this.state.width < 1400 && (
            <Row className="ZoomPageHeader header-md">
              <div className="d-flex flex-column header-content">
                <h1 className="title-width primary_font">Integrate Cialfo with Zoom to streamline distance counseling</h1>
                <div className="d-flex flex-column">
                  <span>Enter your school's Cialfo URL</span>
                  <div className="d-flex">
                    <input value={this.state.subDomain} onChange={this.handleChange} className="form-control input" placeholder="your-cialfo-url"></input>
                    <span className="input-label">.cialfo.co</span>
                    <button className="btn-sign-in" disabled={this.state.subDomain.length == 0} onClick={this.handleClick}>Sign in to integrate</button>
                  </div>
                </div>
              </div>
            </Row>
          )}
        </MediaQuery>
        {/* MOBILE HEADER */}
        <MediaQuery query="(max-device-width: 1223px)">
          <div className="d-flex flex-column align-items-center mobile-background">
            <h1 className="title-width primary_font text-small header-sm">Integrate Cialfo with Zoom to streamline distance counseling</h1>
            <div className="d-flex flex-column form-sm-container">
              <span className="mb-2 bold">Enter your school's Cialfo URL</span>
              <div className="d-flex mb-2">
                <input value={this.state.subDomain} onChange={this.handleChange} className="form-control input" placeholder="your-cialfo-url"></input>
                <span className="input-label sm">.cialfo.co</span>
              </div>
              <div className="d-flex mb-2">
                <button disabled={this.state.subDomain.length == 0} onClick={this.handleClick} className="btn-sign-in sm" >Sign in to integrate</button>
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* FULL SCREEN MAIN */}
        <MediaQuery query="(min-device-width: 1224px)">
          <Row className="ZoomPageMain mt-4 mb-5">
            <Container className="ZoomPageMainContainer mb-5">
              <Row className="mb-4">
                <Col className="d-flex justify-content-center">
                  <img className="ZoomCialfoImg" src={ZoomCialfoImg}></img>
                </Col>
              </Row>
              <Row className="mt-4 mb-4">
                <Col className="d-flex justify-content-center">
                  <p className="main-text-p">
                  Streamline your distance counseling workflow with the Zoom integration on Cialfo. Instantly add videoconferencing links and ensure the right details are sent out, making it easy for everyone to join your meetings on time.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CameraIcon}></img>
                  </div>
                  <span className="ml-2">Create or add Zoom Meetings with one click</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CalendarIcon}></img>
                  </div>
                  <span className="ml-2">Zoom meeting information added to calendars on Google or Office 365</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CialfoIcon}></img>
                  </div>
                  <span className="ml-2">Integrate your school’s Zoom account with Cialfo</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={HeadsetIcon}></img>
                  </div>
                  <span className="ml-2">Instantly join Zoom meetings</span>
                </Col>
              </Row>
            </Container>
          </Row>
        </MediaQuery>
        {/* MOBILE MAIN */}
        <MediaQuery query="(max-device-width: 1223px)">
          <Row className="ZoomPageMain sm mb-5">
            <Container className="ZoomPageMainContainer mb-5">
              <Row className="mb-4">
                <Col className="d-flex justify-content-center">
                  <img className="ZoomCialfoImg sm" src={ZoomCialfoImg}></img>
                </Col>
              </Row>
              <Row className="main-text-row sm">
                <Col className="d-flex justify-content-center">
                  <p className="main-text-p sm">
                  Streamline your distance counseling workflow with the Zoom integration on Cialfo. Instantly add videoconferencing links and ensure the right details are sent out, making it easy for everyone to join your meetings on time.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CameraIcon}></img>
                  </div>
                  <span className="ml-2 text-sm">Create or add Zoom Meetings with one click</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CalendarIcon}></img>
                  </div>
                  <span className="ml-2 text-sm">Zoom meeting information added to calendars on Google or Office 365</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={CialfoIcon}></img>
                  </div>
                  <span className="ml-2 text-sm">Integrate your school’s Zoom account with Cialfo</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex align-items-center">
                  <div className="icon-container">
                    <img src={HeadsetIcon}></img>
                  </div>
                  <span className="ml-2 text-sm">Instantly join Zoom meetings</span>
                </Col>
              </Row>
            </Container>
          </Row>
        </MediaQuery>
      </Container>
    )
  }
}

export default withRouter(Zoom);
