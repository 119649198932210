import React from "react";
import Container from "react-bootstrap/Container";
import SolutionsSubPage from "./solutions/SolutionsSubPage";
import { withRouter } from "react-router-dom";
import { DataContext } from "../contexts/DataContext"

class SolutionsSuperintendents extends React.Component {
  static contextType = DataContext;
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    this.context.fetchEntries("homePageHeaderProductImage").then((response) => {
      let data = this.context.setContent(response, "solutionsSuperintendents")
      data.solSubSolutionItems = data.solSubSolutionItems.map(item => this.context.setContent([item]));
      this.setState({
        data: data
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.locale !== this.props.match.params.locale) {
      this.context.fetchEntries("homePageHeaderProductImage").then((response) => {
        let data = this.context.setContent(response, "solutionsSuperintendents")
        data.solSubSolutionItems = data.solSubSolutionItems.map(item => this.context.setContent([item]));
        this.setState({
          data: data
        })
      });
    }
  }

  render() {
    return (
      <Container fluid={true}>
        <SolutionsSubPage
          data={this.state.data}
        />
      </Container>
    );
  }
}

export default withRouter(SolutionsSuperintendents);
