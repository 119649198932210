import React from "react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import "../Layout/Layout.css";
import TranslateButton from "./TranslateButton";
import PathToRegexp from "path-to-regexp";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";

class FullScreenHeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  updateLocale = locale => {
    this.props.updateLocale(locale);
  };

  identifySpace = location => {
    const ROUTE = "/:space/:locale/:path*";
    const routeComponents = PathToRegexp(ROUTE).exec(location.pathname);
    if (routeComponents) {
      return routeComponents[1];
    } else return "hey";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        space: this.identifySpace(this.props.location)
      });
    }
  }

  componentDidMount() {
    this.identifySpace(this.props.location);
  }

  buildCustomNavLinks = () => {
    let table = [];
    for (let i = 0; i < 7; i++) {
      if (this.props.name[i] != null) {
        if (this.props.link[i].includes('http')) {
          table.push(
            <div key={i}>
              <a
                activeClassName="activeStyle"
                className="nav-link"
                href={this.props.link[i]}
              >
                {this.props.name[i]}
              </a>
            </div>
          );
        } else {
          table.push(
            <div key={i}>
              <NavLink
                activeClassName="activeStyle"
                className="nav-link"
                to={this.props.link[i]}
              >
                {this.props.name[i]}
              </NavLink>
            </div>
          );
        }
      }
    }
    return table;
  };

  render() {
    const { linkDemo, nameDemo } = this.props;
    return (
      <Nav>
        {this.buildCustomNavLinks()}

        {this.identifySpace(this.props.location) === "cn" && (
          <TranslateButton updateLocale={this.updateLocale} />
        )}
        {linkDemo && !linkDemo.includes('http') && (
          <div>
            <NavLink
              activeClassName="activeStyle"
              className="demo-page-link"
              to={linkDemo}
            >
              <Button
                className="nav-link-button sharp-corners-button"
                size="sm"
                variant="primary"
              >
                {nameDemo}
              </Button>
            </NavLink>
          </div>
        )}
        {linkDemo && linkDemo.includes('http') && (
          <div>
            <a
              activeClassName="activeStyle"
              className="nav-link"
              href={linkDemo}
            >
              {nameDemo}
            </a>
          </div>
        )}
      </Nav>
    );
  }
}

export default withRouter(FullScreenHeaderLinks);
